// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bM_kC";
export var caseStudyBackground__bgColor = "bM_ky";
export var caseStudyBackground__lineColor = "bM_kz";
export var caseStudyHead__imageAbsolute = "bM_kw";
export var caseStudyHead__imageWrapper = "bM_kv";
export var caseStudyProjectsSection = "bM_kD";
export var caseStudyQuote__bgRing = "bM_kx";
export var caseStudyTech = "bM_kL";
export var caseStudyVideo = "bM_kM";
export var caseStudyVideo__ring = "bM_kN";
export var caseStudy__bgDark = "bM_ks";
export var caseStudy__bgLight = "bM_kr";